import Mock from 'mockjs'

const Random = Mock.Random

//用户数据
const userData = () => {
    let users = []
    for (let i = 0; i < 100; i++) {
        let user = {
            'id': i + 1,
            'name': Random.cname(),
            'address': Mock.mock('@county(true)'),
            'phone': Mock.mock(/^1[0-9]{10}$/),
            'date': Random.date('yyyy-MM-dd')
        }
        users.push(user);
    }
    return users;
}

//实例图片
const productPhoto = () => {
    let photos = []
    for (let i = 0; i < 200; i++) {
        let photo = {
            'id': i + 1,
            'url': Random.dataImage('200x100', 'Hello Mock.js!'),
            'alt': Random.color(),
            'thumbnailUrl': Random.dataImage('400x200', 'Hello Mock.js!')
        }
        photos.push(photo);
    }
    return photos;
}

//新闻
const newsList = () => {
    let newsList = []
    for (let i = 0; i < 200; i++) {
        let news = {
            'id': i + 1,
            'title': Mock.mock('@title()'),
            'date': Random.date('yyyy-MM-dd')
        }
        newsList.push(news);
    }
    return newsList;
}

//天安简介图片
const aboutPicList = () => {
    let aboutPicList = [{ id: 0, idView: require("../public/image/about-pic2.jpg") },
    { id: 1, idView: require("../public/image/about-pic3.jpg") }]
    return aboutPicList;
}

//荣誉图片
const honorList = () => {
    let honorList = []
    for (let i = 0; i < 160; i++) {
        let honor = {
            'id': i + 1,
            'url': Random.dataImage('400x200', 'Hello Mock.js!'),
            'alt': Random.color(),
            'thumbnailUrl': Random.dataImage('800x500', 'Hello Mock.js!')
        }
        honorList.push(honor);
    }
    return honorList;
}

//联系我们
const contactList = () => {
    let contactList = [{ id: 0,dep:"汽饰材料销售部", contacts:"林先生",tel:"0757-82012203",fax:"0757-82012209",address:"佛山市禅城区南庄镇吉利工业园新源一路30号 "},
    {id: 1,dep:"装饰材料销售部", contacts:"欧阳先生",tel:"0757-82012203",fax:"0757-82012209",address:"佛山市禅城区南庄镇吉利工业园新源一路30号 "},
    {id: 2,dep:"压延薄膜销售部", contacts:"欧阳先生",tel:"0757-82012203",fax:"0757-82012209",address:"佛山市禅城区南庄镇吉利工业园新源一路30号 "},
    {id: 3,dep:"压延硬片销售部", contacts:"欧阳先生",tel:"0757-82012203",fax:"0757-82012209",address:"佛山市禅城区南庄镇吉利工业园新源一路30号 "},]
    return contactList;
}

//招聘信息
const jobList = () => {
    let jobList = [{ id: 0,jobname:"外贸业务员", sex:"不限",education:"本科",cpmpany:"天安新材",num:"1人",workyear:"不限",workplace:"佛山禅城",age:"22-35",deadline:"长期有效",jobdes:"1.本科以上学历，专业不限； 2.性格开朗，具有为目标吃苦耐劳的精神； 3.能接受长期国外出差； 4.英语口语良好（英语六级或以上），或其他小语种； 5、有相关的行业工作经验优先考虑。",tel:"联系电话：0757-82012267 联系人： 杨小姐 邮箱：hr4@tianantech.com"},
    { id: 1,jobname:"国内业务员", sex:"男",education:"本科"},
    { id: 2,jobname:"技术储备", sex:"不限",education:"本科"},
    { id: 3,jobname:"涂料工程师", sex:"不限",education:"本科"},
    { id: 4,jobname:"EB机技术员", sex:"不限",education:"本科"},
    { id: 5,jobname:"质检", sex:"不限",education:"本科"},
    { id: 6,jobname:"生产工", sex:"不限",education:"本科"},
    { id: 7,jobname:"生产工", sex:"不限",education:"本科"},
    { id: 8,jobname:"生产工", sex:"不限",education:"本科"},
    { id: 9,jobname:"生产工", sex:"不限",education:"本科"},
    { id: 10,jobname:"生产工", sex:"不限",education:"本科"}]
    return jobList;
}

//用人机制
const hrVisionList = () => {
    let hrVisionList = [{ id: 0,title:"人才理念", content:"广东天安新材料股份有限公司成立于2000年,总部位于佛山市禅城区,于2017年9月在上海证券交易所正式挂牌上市，成功登陆A股主板，股票代码:603725。 公司致力于为员工提供充分施展才华的舞台,为有志成才的员工创造优越的成长条件，让普通的人变成优秀的人，优秀的人变成卓越的人，充分实现自我价值。 培养和造就一支赢得市场领先、创造组织优势、引领价值导向、有使命感与责任感的人才队伍，支持战略目标的实现，是天安新材持续不断的人才追求。",img:require("../public/image/hrdes1.jpg")},
    { id: 1,title:"发展前景", content:"天安新材自2000年创建以来，在技术研发、信息、设备、文化等方面，形成自身独特优势，认定为“国家火炬计划高新技术企业”、“省聚合物工程技术研究开发中心”等，并成立了博士后工作站流动分站，是国内唯一研发生产环保高端室内空间饰面材料和汽车内饰饰面材料的股份制民营企业。 2013年，天安新材为满足战略发展需求，成立安徽天安新材料有限公司,并于2015年开始量产。 未来，天安新材将继续发展、扩大产品的应用领域与生产规模，争创国内多元化集团公司，并进一步实现成为跨国集团公司的目标。",img:"../public/image/hrdes2.jpg"},
    { id: 2,title:"薪酬与绩效", content:"确保公司战略发展需要，促进人才竞争优势，公司设计了多方面的薪酬激励政策，鼓励员工向一专多能化发展；同时，聘请了专业的管理顾问公司进行专业辅导。 强调绩效管理以结果为导向，对不同层级的员工采用不同的考核方式，不断提高员工主动性和积极性，结合不同类型的培训，达到业绩提升，营造一个激励员工奋发向上的工作氛围。其中，中高层人员的考核与公司的经营挂钩，从多方面确保战略目标落地。 公司上市前，在历次增资扩股中，优先考虑骨干人才持股；对于后续引入的人才，公司也积极研究股权激励方案，通过建立长期激励机制，实现人才与企业共同发展。",img:"../public/image/hrdes3.jpg"},
    { id: 3,title:"培训与晋升", content:"公司根据不同岗位的特点，设置了不同的职业发展通道，为所有员工搭建职业发展的平台。 员工可根据自己性格和专长，选择不同的职业发展路线；公司亦会根据每个人的性格特征，培养不同专业的人才。培养方式多样化，不仅限于学历提升、技能培训、外派学习等。 天安新材为员工提供灵活的薪酬、福利待遇，每季度更有薪资调整与晋升的机会，让优秀的员工有更多的发展机会。",img:"../public/image/hrdes4.jpg"},
    { id: 4,title:"团队建设", content:"完善的福利机制：公司为全体员工购买五险一金，根据工作性质购买商业保险；提供各类津贴、补贴等。文化氛围：定期举行公司年会，以及各类评优活动、瑜伽活动、羽毛球活动；不定期举行员工运动会、户外拓展、相亲联谊活动等丰富多彩的文体活动。",img:"../public/image/hrdes5.jpg"},]
    return hrVisionList;
}

Mock.mock('/myapi/users', userData)

Mock.mock('/myapi/productPhoto', productPhoto)

Mock.mock('/myapi/newsList', newsList)

Mock.mock('/myapi/aboutPicList', aboutPicList)

Mock.mock('/myapi/honorList', honorList)

Mock.mock('/myapi/contactList', contactList)

Mock.mock('/myapi/jobList', jobList)

Mock.mock('/myapi/hrVisionList', hrVisionList)