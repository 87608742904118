<template>
  <header>
    <div class="container-fluid top-stock hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <p>欢迎光临广东天安新材官网！</p>
          </div>
          <div class="col-sm-6 dropdown">
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              class="pull-right top-language"
              ><span class="glyphicon glyphicon-globe"></span>&nbsp;语言<span
                class="caret"
              ></span
            ></a>
            <ul class="dropdown-menu pull-right">
              <li>
                <router-link class="text-center" to="/">简体中文</router-link>
              </li>
              <li>
                <router-link to="/EN" class="text-center">English</router-link>
              </li>
              <li>
                <router-link to="/JPN" class="text-center">日本語</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="default-nav">
      <div class="navbar navbar-default">
        <div class="container">
          <div class="nav-logo">
            <router-link to="/">
              <img
                class="img-responsive"
                :src="TaLogo"
                alt="广东天安新材料股份有限公司"
                style="height: 100%; width: auto"
              />
            </router-link>
          </div>
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navBar"
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" id="navBar">
            <ul class="nav navbar-nav">
              <li><router-link to="/">首页</router-link></li>

              <li class="dropdown">
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  >走进天安<span class="caret"></span>
                </a>
                <ul class="dropdown-menu" role="menu">
                  <li><router-link to="/About">企业简介</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/Culture">企业文化</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <router-link to="/Companyhistory">企业历程</router-link>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/Honor">企业荣誉</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/HR">人力资源</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/News">媒体中心</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/Contact">联系我们</router-link></li>
                  <li role="separator" class="divider visible-xs"></li>
                </ul>
              </li>

              <li class="dropdown">
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  >公司实力<span class="caret"></span>
                </a>
                <ul class="dropdown-menu" role="menu">
                  <li>
                    <router-link to="/DesignCenter">设计中心</router-link>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/RD">科研中心</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/Fabricate">精益制造</router-link></li>
                  <li role="separator" class="divider visible-xs"></li>
                </ul>
              </li>

              <li><router-link to="/Product">产品中心</router-link></li>

              <li><router-link to="/Brand">品牌&客户</router-link></li>

              <li><router-link to="/IR">投资者关系</router-link></li>

              <li class="dropdown visible-xs">
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><span class="glyphicon glyphicon-globe"></span
                  >&nbsp;&nbsp;语言<span class="caret"></span
                ></a>
                <ul class="dropdown-menu">
                  <li><router-link to="/">简体中文</router-link></li>
                  <li role="separator" class="divider"></li>
                  <router-link to="/EN">English</router-link>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/JPN">日本語</router-link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>



<script>
export default {
  name: "TaNav",
  data() {
    return {
      TaLogo: require("../../public/image/ta-logo.png"),
    };
  },
  mounted() {
    var $dropdownLi = $("li.dropdown");

    $dropdownLi
      .mouseover(function () {
        $(this).addClass("open");
      })
      .mouseout(function () {
        $(this).removeClass("open");
      });
  },
  methods: {},
};
</script>


<style lang="less" scoped>
/* 顶部栏样式 */
.top-stock {
  height: 30px;
  background-color: #eee;
}

.top-stock .container {
  padding: 0;
}

.top-stock .col-sm-6 {
  height: 30px;
}

.top-stock span {
  line-height: 30px;
  font-size: 14px;
}

.top-stock .top-language {
  text-decoration: none;
}

.top-stock p {
  line-height: 30px;
  font-size: 14px;
  cursor: default;
  text-align: left;
  color: #333;
}

.top-stock p a {
  color: red;
  text-decoration: none;
}

.top-stock ul {
  min-width: 75px;
}
/*导航栏样式*/
.default-nav .navbar-default {
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 25px;
  border: none;
  background-color: #fff;
}

@media (min-width: 768px) {
  .default-nav .navbar-collapse {
    padding: 0;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .default-nav .navbar-default {
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-nav > li > a {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .default-nav .navbar-collapse {
    float: right;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .default-nav .navbar-collapse {
    float: left;
    margin-left: -15px;
  }

  .default-nav .navbar-collapse li a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .default-nav .navbar-default {
    padding-top: 16px;
    padding-bottom: 12px;
  }
}

.default-nav .dropdown-menu {
  right: auto;
  left: 0;
}

.default-nav .container {
  padding: 0;
}

.nav-logo {
  float: left;
  height: 43px;
  /*margin-top: 5px;*/
  overflow: hidden;
}

.nav-logo a {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .language-select {
    float: left;
  }
}

@media (min-width: 992px) {
  .language-select {
    float: right;
  }
}
/deep/ .navbar-default .navbar-nav> .open a:first-child{
  background-color: #fff;
}

</style>