<template>
  <div class="companyannouncements">
    <h3>公司公告</h3>
    <hr />

    <ul class="list-group" v-for="item in reportList" :key="item.id">
      <li class="list-group-item">
        <span class="badge" style="cursor: default">{{ item.createtime }}</span>
        <a v-if="item.filename.substring(0,2)!='..' && item.filename.substring(0,15)!='/upload/Report/'" :href="'../upload/Report/' + item.filename" target="_blank">
        {{item.title}}
        </a>
        <a v-else-if="item.filename.substring(0,15)=='/upload/Report/'" :href="'..'+item.filename" target="_blank">
        {{item.title}}
        </a>
        <a v-else-if="item.filename.substring(0,2)=='..'" :href="item.filename" target="_blank">
        {{item.title}}
        </a>
      </li>
      <hr />
    </ul>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :pager-count="5"
          :current-page="pageNum" 
          :page-size="pageSize"
          layout="total,prev,pager,next"
          :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "companyannouncements",
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 15,
      reportList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(pageNum,pageSize) {
      let url = `/ir/list/0/1/${this.pageNum}`
      let data = {
        params: {
          pageNum,
          pageSize
        }
      }
      let res = await this.$axios.get(url, data)
        this.reportList = res.data.data;
        this.total = res.data.totalsize;

    },
    //分页
    //数据条数发生变化
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(this.pageNum, this.pageSize);
    },
    //翻页触发
    handleCurrentChange(currPage) {
      this.pageNum = currPage;
      this.getList(this.pageNum, this.pageSize);
    },
  },
};
</script>