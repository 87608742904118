<template>
  <div>
    <div class="footnav">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-sm-12 footmenu col-xs-12">
            <div class="col-md-3 col-sm-3 col-xs-3">
              <ul>
                <li>
                  <h4>走进天安</h4>
                </li>
                <li><router-link to="/About">企业简介</router-link></li>
                <li><router-link to="/Culture">企业文化</router-link></li>
                <li>
                  <router-link to="/Companyhistory">企业历程</router-link>
                </li>
                <li><router-link to="/Honor">企业荣誉</router-link></li>
                <li><router-link to="/HR">人力资源</router-link></li>
                <li><router-link to="/News">媒体中心</router-link></li>
                <li><router-link to="/Contact">联系我们</router-link></li>
              </ul>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-3">
              <ul>
                <li>
                  <h4>公司实力</h4>
                </li>
                <li><router-link to="/DesignCenter">设计中心</router-link></li>
                <li><router-link to="/RD">科研中心</router-link></li>
                <li><router-link to="/Fabricate">精益制造</router-link></li>
              </ul>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-3">
              <ul>
                <li>
                  <h4>产品中心</h4>
                </li>
                <li><router-link to="/Product">装饰面料</router-link></li>
                <li><router-link to="/Product">汽饰面料</router-link></li>
                <li><router-link to="/Product">功能薄膜</router-link></li>
              </ul>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-3">
              <ul>
                <li>
                  <h4>其他</h4>
                </li>
                <li><router-link to="/Brand">品牌&客户</router-link></li>
                <li><router-link to="/IR">投资者关系</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="phone">
              <ul>
                <li>
                  <address>
                    总公司地址：佛山市禅城区南庄镇吉利工业园新源一路30号
                  </address>
                </li>
                <li>
                  <p>
                    <span class="glyphicon glyphicon-earphone"></span
                    >：0757-82012222
                  </p>
                </li>
                <li>
                  <address>
                    安徽分公司地址：安徽省滁州市全椒县十谭现代产业园光辉大道20号
                  </address>
                </li>
                <li>
                  <p>
                    <span class="glyphicon glyphicon-earphone"></span
                    >：0550-5361109
                  </p>
                </li>
                <li>
                  <p>汽饰材料销售部：0757-82012203</p>
                </li>
                <li>
                  <p>装饰材料销售部：0757-82012232</p>
                </li>
                <li>
                  <p>压延薄膜销售部：0757-82025051</p>
                </li>
                <li>
                  <p>硬质压延销售部：13725438545</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_links">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5>友情链接</h5>
            <div class="link_items">
              <a
                href="http://gfz.tianantech.com"
                target="_blank"
                title="天安高分子"
                >天安高分子</a
              >
              <a
                href="https://www.tiananjicheng.com"
                target="_blank"
                title="天安集成"
                >天安集成</a
              >
              <a href="http://www.rexinnet.com" target="_blank" title="瑞欣材料"
                >瑞欣材料</a
              >
              <a
                href="http://www.eagleceramics.com/"
                target="_blank"
                title="鹰牌陶瓷"
                >鹰牌陶瓷</a
              >
              <a
                href="http://www.eaglebrandgroup.com"
                target="_blank"
                title="鹰牌集团"
                >鹰牌集团</a
              >
              <a
                href="http://www.nfadi.com.cn/"
                target="_blank"
                title="佛山南方建筑设计院"
                >南方建筑设计院</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div>
        <div class="footer-copy">
          Copyright@ {{ copyrightYear }} 版权所有 &copy;
          广东天安新材料股份有限公司
        </div>
        <div class="footer-copy">
          <a href="http://beian.miit.gov.cn">粤ICP备09006779号-2</a>
          &nbsp;&nbsp;<a href="http://mail.tianantech.com/"
            ><span
              class="glyphicon glyphicon-envelope"
              style="font-size: 12px"
            ></span
            >&nbsp;企业邮箱</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
export default {
  data() {
    return {
      copyrightYear: "",
    };
  },
  mounted() {
    this.nowtime();
  },
  methods: {
    nowtime() {
      // 获取当前年份
      let year = new Date().getFullYear();
      this.copyrightYear = year;
    },
  },
};
</script>

<style scoped>
.footnav {
  background-color: #222222;
  text-align: left;
}
.footnav .container {
  padding: 30px 0 20px 0;
}

.footnav ul li + li {
  margin-top: 10px;
}

.footnav ul li a {
  color: #dddddd;
  text-decoration: none;
}

.footnav ul li h4 {
  color: #eeeeee;
  cursor: default;
}

.footnav ul li a:hover {
  color: #fff;
}

.footnav .phone address {
  color: #ddd;
  margin-bottom: 10px;
  cursor: default;
}

.footnav .phone {
  margin-top: 10px;
}

.footnav .phone h4 {
  color: #eeeeee;
}

.footnav .phone h6 {
  color: #ffffff;
  font-size: 26px;
  cursor: default;
}

.footnav .phone h5 {
  color: #dddddd;
  cursor: default;
}

.footnav .phone p {
  color: #dddddd;
  cursor: default;
}

.footnav .phone hr {
  height: 1px;
  border: none;
  border-top: 1px solid #333333;
  margin: 10px 0;
}

@media (max-width: 992px) {
  .footnav .phone ul {
    text-align: center;
  }

  .footnav .footmenu ul {
    text-align: center;
  }

  .phone {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .footnav .phone ul {
    text-align: left;
  }

  .footmenu {
    display: none;
  }

  .phone {
    margin-top: 0px;
  }

  .footnav .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*页脚样式*/
footer .footer-copy:nth-child(1) {
  color: #dddddd;
  padding: 25px 15px;
  background-color: #282828;
  font-size: 16px;
  text-align: center;
}

footer .footer-copy:nth-child(2) {
  color: #dddddd;
  padding: 20px 15px;
  background-color: #222222;
  font-size: 16px;
  text-align: center;
}

.footer-copy {
  cursor: default;
}

footer .footer-copy a {
  text-decoration: none;
  color: #dddddd;
}

footer .footer-copy a:hover {
  color: #fff;
}

@media (max-width: 767px) {
  footer .footer-copy:nth-child(1) {
    font-size: 14px;
    text-align: left;
  }

  footer .footer-copy:nth-child(2) {
    font-size: 14px;
    text-align: left;
  }
}

/*友情链接*/
.footer_links {
  background-color: #222222;
  text-align: left;
}
.footer_links h5 {
  color: #eeeeee;
}
.footer_links .link_items a {
  display: inline-block;
  margin: 0 8px 8px 0;
}
.footer_links a {
  color: #dddddd;
  cursor: default;
}

.footer_links a:hover {
  /*color: #fff;*/
  color: #ff9900;
  text-decoration: none;
  cursor: pointer;
}
.footer_links a:link {
  text-decoration: none;
}
</style>
