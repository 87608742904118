<template>
  <div id="app">
    <!-- 返回顶部 -->
    <el-backtop class="hidden-xs">
      <i class="el-icon-top"></i>
    </el-backtop>
    <!-- 头部 -->
    <div class="ta-header">
      <div v-if="0 === navshow"><cn-nav></cn-nav></div>
      <div v-if="1 === navshow"><en-nav></en-nav></div>
      <div v-if="2 === navshow"><jpn-nav></jpn-nav></div>
      <div v-if="3===navshow"></div>
    </div>
    <!-- 内容 -->
    <div class="ta-main">
      <!-- keep-alive是vue中的一个抽象组件，被keep-alive包含的路由或组件将不会mounted，只在第一次进入时会初始化，之后进入需要手动刷新。
只针对列表页进行状态缓存，对路由进行区分，keepAlive属性为true时用keep-alive组件包含。 -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
    <!-- 底部 -->
    <div class="ta-footer">
      <div v-if="0 === navshow"><cn-footer></cn-footer></div>
      <div v-if="1 === navshow"><en-footer></en-footer></div>
      <div v-if="2 === navshow"><en-footer></en-footer></div>
      <div v-if="3 === navshow"></div>
    </div>
  </div>
</template>


<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  padding: 0;
  margin: 0;
}
</style>

<script>
import CnNav from "./components/TANav.vue";
import CnFooter from "./components/TAFooter.vue";
import EnFooter from "./components/TAFooter-En.vue";
import EnNav from "./components/TANav-En.vue";
import JpnNav from "./components/TANav-Jpn.vue";
import { showLoading, hideLoading } from "./assets/js/Loading";
export default {
  name: "app",
  components: {
    CnNav,
    CnFooter,
    EnFooter,
    EnNav,
    JpnNav,
  },
  data() {
    return {
      navshow: 0,
    };
  },
  beforeMount() {
    showLoading();
  },
  mounted() {
    hideLoading();
  },
  watch: {
    //监听路由切换导航栏
    $route: {
      handler: function (route) {
        //console.log(route.path);
        if (route.path.indexOf("EN") != -1) {
          this.navshow = 1;
          document.title = "Guangdong Tianan New Material Co., LTD";
        } else if (route.path.indexOf("JPN") != -1) {
          this.navshow = 2;
          document.title = "広東天安新材料有限公司";
        }else if(route.path.indexOf("Media")!=-1){
          this.navshow=3;
        }
         else {
          this.navshow = 0;
          document.title = "广东天安新材料股份有限公司";
        }
      },
      immediate: true,
    },
  },
};
</script>
