<template>
  <header>
    <div class="container-fluid top-language hidden-xs">
      <div class="container">
        <div class="row">
          <div class="pull-right">
            <ul>
              <li>
                <router-link to="/" 
                  ><img src="../../public/image/CN.png" /></router-link
                >&nbsp;&nbsp;<router-link to="/">中文</router-link>
              </li>
              <li>
                <router-link to="/EN" 
                  >
                  ><img src="../../public/image/EN.png" /></router-link
                >&nbsp;&nbsp;<router-link to="/EN">English</router-link>
              </li>
              <li>
                <router-link to="/JPN"
                  ><img src="../../public/image/JAN.png" /></router-link
                >&nbsp;&nbsp;<router-link to="/JPN">日本語</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="default-nav">
      <div class="navbar navbar-default">
        <div class="container">
          <div class="nav-logo">
            <router-link to="/EN">
              <img
                class="img-responsive"
                :src="TaLogo"
                alt="广东天安新材料股份有限公司"
                style="height: 100%; width: auto"
              />
            </router-link>
          </div>
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navBar"
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" id="navBar">
            <ul class="nav navbar-nav">
              <li><router-link to="/EN">Home</router-link></li>
              <li><router-link to="/EN/About">About Us</router-link></li>

              <li><router-link to="/EN/Product">Products</router-link></li>
              <li><router-link to="/EN/RD">R&D Center</router-link></li>

              <li><router-link to="/EN/Contact">Contact Us</router-link></li>

              <li class="dropdown visible-xs">
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><span class="glyphicon glyphicon-globe"></span
                  >&nbsp;&nbsp;Language<span class="caret"></span
                ></a>
                <ul class="dropdown-menu">
                  <li><router-link to="/">简体中文</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/EN">English</router-link></li>
                  <li role="separator" class="divider"></li>
                  <li><router-link to="/JPN">日本語</router-link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>



<script>
export default {
  data() {
    return {
      TaLogo: require("../../public/image/ta-logo.png"),
    };
  },
  methods: {},
};
</script>


<style scoped>
/*首页样式*/

.top-language {
  background-color: #f1f2f3;
  height: 30px;
  font-size: 12px;
}

.top-language .container {
  height: 30px;
}

.top-language .container .row {
  height: 30px;
}

.top-language .container .row .pull-right {
  height: 30px;
}

.top-language ul li {
  display: inline-block;
  line-height: 30px;
  margin-left: 10px;
}

.top-language ul li a {
  text-decoration: none;
  color: #333;
}

.top-language ul li a:hover {
  color: #ff9900;
}
/* 顶部栏样式 */
.top-stock {
  height: 30px;
  background-color: #eee;
}

.top-stock .container {
  padding: 0;
}

.top-stock .col-sm-6 {
  height: 30px;
}

.top-stock span {
  line-height: 30px;
  font-size: 14px;
}

.top-stock .top-language {
  text-decoration: none;
}

.top-stock p {
  line-height: 30px;
  font-size: 14px;
  cursor: default;
  text-align: left;
  color: #333;
}

.top-stock p a {
  color: red;
  text-decoration: none;
}

.top-stock ul {
  min-width: 75px;
}
/*导航栏样式*/
.default-nav .navbar-default {
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 25px;
  border: none;
  background-color: #fff;
}

@media (min-width: 768px) {
  .default-nav .navbar-collapse {
    padding: 0;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .default-nav .navbar-default {
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-nav>li>a{
    text-align: left;
  }
}

@media (min-width: 992px) {
  .default-nav .navbar-collapse {
    float: right;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .default-nav .navbar-collapse {
    float: left;
    margin-left: -15px;
  }

  .default-nav .navbar-collapse li a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .default-nav .navbar-default {
    padding-top: 16px;
    padding-bottom: 12px;
  }
}

.default-nav .dropdown-menu {
  right: auto;
  left: 0;
}

.default-nav .container {
  padding: 0;
}

.nav-logo {
  float: left;
  height: 43px;
  overflow: hidden;
}

.nav-logo a {
  margin: 0;
  padding: 0;
}
</style>