import Vue from 'vue'
import Router from 'vue-router'

import IR from "@/views/CN/IR/IR.vue" //投资者关系
import CompanyAnnouncements from "@/views/CN/IR/CompanyAnnouncements.vue" //公司公告
import RegularAnnouncement from "@/views/CN/IR/RegularAnnouncement.vue" //定期公告
import CorporateGovernance from "@/views/CN/IR/CorporateGovernance.vue" //公司治理

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }), //路由跳转后页面回到顶部
  routes: [
    {
      path:'*',
      name:'404',
      component:()=>import('./components/404.vue')
    },
    {
      path: '/',
      name: 'Index',
      component: () => import('./views/CN/Index.vue')
    },
    //天安简介
    {
      path: '/About',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/CN/About.vue')
    },
    {
      // 企业文化
      path: '/Culture',
      name: 'Culture',
      component: () => import('./views/CN/Culture.vue')
    },
    {
      // 企业历程
      path: '/Companyhistory',
      name: 'Companyhistory',
      component: () => import('./views/CN/CompanyHistory.vue')
    },
    {
      // 企业荣誉
      path: '/Honor',
      name: 'Honor',
      component: () => import('./views/CN/Honor.vue')
    },
    {
      // 人力资源
      path: '/HR',
      name: 'hrdes',
      component: () => import('./views/CN/HrDes.vue'),
      meta: {
        keepAlive: true
      }
    },
    {
      // 联系我们
      path: '/Contact',
      name: 'contact',
      component: () => import('./views/CN/Contact.vue')
    },
    {
      path: '/News',
      name: 'News',
      redirect: '/News',
      component: () => import('./views/CN/News.vue'),
      children: [
        {
          path: "/News",
          name: "News-CN",
          component: () => import("./components/NewsList.vue"),
          meta: {
            keepAlive: true
          },
        },
        {
          path: "/Video",
          name: "Video",
          component: () => import("./views/CN/Video.vue"),
          meta: {
            keepAlive: true
          }
        }
      ]
    },
    // {
    //   path: '/Dplayer',
    //   name: 'Dplayer',
    //   component: () => import('./components/Dplayer.vue'),
    // },
    {
      path: '/NewsDetail/:id',
      name: 'NewsDetail',
      component: () => import('./views/CN/NewsDetail.vue'),
    },
    {
      path: '/DesignCenter',
      name: 'DesignCenter',
      component: () => import('./views/CN/DesignCenter.vue')
    },
    {
      path: '/Fabricate',
      name: 'Fabricate',
      component: () => import('./views/CN/Fabricate.vue')
    },
    {
      path: '/Product',
      name: 'Product',
      component: () => import('./views/CN/Product.vue'),
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/Product/ProductDetail/:id',
      name: 'ProductDetail',
      component: () => import('./views/CN/ProductDetail.vue')
    },
    {
      path: '/Brand',
      name: 'Brand',
      component: () => import('./views/CN/Brand.vue')
    },
    {
      path: '/RD',
      name: 'RD',
      redirect: '/RDCenter',
      component: () => import('./views/CN/RD/RD.vue'),
      children: [
        {
          path: '/RDCenter',
          name: 'RDCenter',
          component: () => import('./views/CN/RD/RDCenter.vue')
        },
        {
          path: '/RDResult',
          name: 'RDResult',
          component: () => import('./views/CN/RD/RDResult.vue')
        }
      ]
    },
    {
      path: '/RDDetail/:id',
      name: 'RDDetail',
      component: () => import('./views/CN/RD/RDDetail.vue')
    },
    //投资者关系
    {
      path: '/IR',
      name: 'IR',
      redirect: '/IR/CompanyAnnouncements',
      component: IR,

      children: [
        {
          path: '/IR/CompanyAnnouncements',
          name: 'CompanyAnnouncements',
          component: CompanyAnnouncements,
          //props: true
        },
        {
          path: '/IR/RegularAnnouncement',
          name: 'RegularAnnouncement',
          component: RegularAnnouncement,
        },
        {
          path: '/IR/CorporateGovernance',
          name: 'CorporateGovernance',
          component: CorporateGovernance,
        },
      ]
    },
    //英文版首页
    {
      path: '/EN',
      name: 'index-en',
      component: () => import('./views/EN/Index.vue'),
    },
    //英文版关于天安
    {
      path: '/EN/About',
      redirect: "/EN/AboutDes",
      component: () => import('./views/EN/About.vue'),
      children: [
        {
          path: "/EN/About",
          name: "AboutDes-en",
          component: () => import('./views/EN/AboutDes.vue'),
        },
        {
          path: "/EN/CompanyHistory",
          name: "CompanyHistory-en",
          component: () => import('./views/EN/CompanyHistory.vue'),
        },
        {
          path: "/EN/Certificate",
          name: "Certificate-en",
          component: () => import('./views/EN/Certificate.vue'),
        }
      ]
    },
    //英文版产品
    {
      path: '/EN/Product',
      name: 'Product-en',
      component: () => import('./views/EN/Product.vue')
    },
    //英文版产品详情
    {
      path: '/EN/Product/ProductDetail/:id',
      name: 'ProductDetail-en',
      component: () => import('./views/EN/ProductDetail.vue')
    },
    //英文版联系我们
    {
      path: '/EN/Contact',
      name: 'Contact-en’',
      component: () => import('./views/EN/Contact.vue')
    },
    //英文版研发
    {
      path: '/EN/RD',
      name: 'RD-en',
      redirect: "/EN/RDCenter",
      component: () => import('./views/EN/RD/RD.vue'),
      children: [
        {
          path: "/EN/RDCenter",
          name: "RDCenter-en",
          component: () => import('./views/EN/RD/RDCenter.vue'),
        },
        {
          path: "/EN/Technical",
          name: "Technical-en",
          component: () => import('./views/EN/RD/Technical.vue'),
        },

      ]
    },
    //日文版首页
    {
      path: '/JPN',
      name: 'Index-jpn',
      component: () => import('./views/JPN/Index.vue')
    },
    //日文版联系我们
    {
      path: '/JPN/Contact',
      name: 'Contact-jpn’',
      component: () => import('./views/JPN/Contact.vue')
    },
    //日文版产品
    {
      path: '/JPN/Product',
      name: 'Product-jpn',
      component: () => import('./views/JPN/Product.vue')
    },
    //日文版产品详情
    {
      path: '/JPN/Product/ProductDetail/:id',
      name: 'ProductDetail-jpn',
      component: () => import('./views/JPN/ProductDetail.vue')
    },
    //日文版关于天安
    {
      path: '/JPN/About',
      name: 'About-jpn',
      redirect: "/JPN/AboutDes",
      component: () => import('./views/JPN/About.vue'),
      children: [
        {
          path: "/JPN/About",
          name: "AboutDes-jpn",
          component: () => import('./views/JPN/AboutDes.vue'),
        },
        {
          path: "/JPN/CompanyHistory",
          name: "CompanyHistory-jpn",
          component: () => import('./views/JPN/CompanyHistory.vue'),
        },
        {
          path: "/JPN/Certificate",
          name: "Certificate-jpn",
          component: () => import('./views/JPN/Certificate.vue'),
        }
      ]
    },
    //英文版研发
    {
      path: '/JPN/RD',
      name: 'RD-jpn',
      redirect: "/JPN/RDCenter",
      component: () => import('./views/JPN/RD/RD.vue'),
      children: [
        {
          path: "/JPN/RDCenter",
          name: "RDCenter-jpn",
          component: () => import('./views/JPN/RD/RDCenter.vue'),
        },
        {
          path: "/JPN/Technical",
          name: "Technical-jpn",
          component: () => import('./views/JPN/RD/Technical.vue'),
        },

      ]
    },
    //市场部视频路由
    {
      path:'/Media/V1',
      name: 'V1’',
      component: () => import('./views/Media/V1.vue')
    },
    {
      path:'/Media/V2',
      name: 'V2’',
      component: () => import('./views/Media/V2.vue')
    },
    {
      path:'/Media/V3',
      name: 'V3’',
      component: () => import('./views/Media/V3.vue')
    },
  ]
})

