import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui' //引入ElementUI
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './mock.js'
import jquery from 'jquery' //引入jq
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/main.css'
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'
import '../node_modules/aos/dist/aos.css' //动画库
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import 'font-awesome/css/font-awesome.min.css'
import _ from 'lodash'
import './assets/css/jazz-timeline.css'
Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  //失败时显示的图片
  error: require('./assets/img/error.png'),
  //加载时显示的GIF图
  loading: require('./assets/img/loading.gif'),
  //尝试加载几次
  attempt: 1
}
);


//路由跳转后，页面回到顶部
// router.afterEach(() => {
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
// })


Vue.config.productionTip = false  //设置为 false 以阻止 vue 在启动时生成生产提示
Vue.config.devtools = false  //生产环境设置为false
Vue.use(ElementUI);
Vue.use(AOS);
Vue.use(preview);
//Vue.use(VueAxios);

//设置api地址
axios.defaults.baseURL = 'http://www.tianantech.com/api/'
Vue.prototype.$axios = axios;  //全局注册，使用方法为:this.$axios
Vue.prototype.$video = Video;
Vue.prototype._ = _


new Vue({
  //el:"#app",
  router,
  jquery,
  render: h => h(App)
}).$mount("#app")
