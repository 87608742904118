<template>
  <div class="IR">
    <div class="banner">
      <img
        src="../../../../public/image/IR.jpg"
        alt=""
        class="img-responsive"
      />
    </div>
    <div class="container frame-content">
      <div class="dropdown left-menu">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenu1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          菜单
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li v-for="i in sitebarList" :key="i.id" @click="handle(i.id)">
            <a>{{ i.name }}</a>
          </li>
          <li>
            <a href="http://sns.sseinfo.com/company.do?uid=102268">投资咨询</a>
          </li>
        </ul>
      </div>

      <div class="left-sidebar">
        <div class="list-group">
          <a class="list-group-item active hidden-xs" style="cursor: default">
            <h3>投资者关系</h3>
          </a>

          <div
            class="list-group-item ir-left-menu"
            v-for="item in sitebarList"
            :class="{ isActive: item.id == activeId }"
            :key="item.id"
            @click="handle(item.id)"
          >
            {{ item.name }}
          </div>

          <a
            href="http://sns.sseinfo.com/company.do?uid=102268"
            target="_blank"
            class="list-group-item"
            >投资咨询</a
          >
        </div>
      </div>

      <div class="text-content">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeId: 1,
      sitebarList: [
        { id: 1, name: "公司公告" },
        { id: 2, name: "定期报告" },
        { id: 3, name: "公司治理" },
      ],
    };
  },
  methods: {
    handle(id) {
      if (id == 1) {
        this.activeId = 1;
        this.$router.push({ name: "CompanyAnnouncements" });
      } else if (id == 2) {
        this.activeId = 2;
        this.$router.push({ name: "RegularAnnouncement" });
      } else if (id == 3) {
        this.activeId = 3;
        this.$router.push({ name: "CorporateGovernance" });
      }
    },
  },
  watch: {
    // let router_path = this.$route.path;
    $route: {
      handler: function (route) {
        if (route.path === "/IR/RegularAnnouncement") {
          this.activeId = 2;
        } else if (route.path === "/IR/CorporateGovernance") {
          this.activeId = 3;
        } else {
          this.activeId = 1;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped>
/*投资者关系修改样式*/
.banner {
  margin-bottom: 60px;
}
.left-sidebar {
  float: left;
  width: 26%;
  text-align: left;
}
.left-sidebar a,
.left-sidebar div {
  cursor: pointer;
}
@media (min-width: 768px) {
  .frame-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.left-menu {
  margin-bottom: 25px;
  display: none;
}

.text-content {
  float: right;
  width: 70%;
  background-color: #fff;
  margin-bottom: 60px;
  padding: 1% 2% 2% 2%;
}

@media (max-width: 767px) {
  .left-sidebar {
    display: none;
  }

  .text-content {
    float: none;
    width: 100%;
  }

  .left-menu {
    display: block;
    text-align: left;
  }
}

/deep/ .text-content h3 {
  text-align: left;
}
/deep/ .text-content ul li {
  border: none;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

/deep/ .text-content ul li a {
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

/deep/ .text-content ul li a:hover {
  color: #ff9900;
}
/deep/ .badge {
  background-color: #0d6fda;
  /*border-radius:10px;*/
}
.isActive {
  color: #ff9900;
}
.ir-left-menu:hover {
  background: #f5f5f5;
}
</style>